<template>
  <v-card flat color="grey-lighten-4" class="mb-6">
    <v-card-title>
      {{ $t("components.IntegrationSelector.title") }}
    </v-card-title>
    <v-divider/>
    <v-card-text>
      <h1 class="text-center">{{ $t("components.IntegrationSelector.global") }}</h1>
      <v-divider class="my-3" />
      <configuration-section-row>
        <template #name>
          {{ t(`components.IntegrationSelector.dlocal`) }}
        </template>
        <labeled-switch
          v-model="integrations.dlocal"
        />
      </configuration-section-row>
      <configuration-section-row>
        <template #name>
          {{ t(`components.IntegrationSelector.d6`) }}
        </template>
        <labeled-switch
          v-model="integrations.d6"
        />
      </configuration-section-row>
      <configuration-section-row>
        <template #name>
          {{ t(`components.IntegrationSelector.payfast`) }}
        </template>
        <labeled-switch
          v-model="integrations.payfast"
        />
      </configuration-section-row>
      g      <configuration-section-row>
        <template #name>
          OneTrust Script ID
        </template>
        <template #comment>
          Shows the Cookie Manager banner.
        </template>
        <v-text-field
          color="primary"
          label="Script ID"
          v-model="integrations.oneTrustScriptId"
        />
      </configuration-section-row>
      <configuration-section-row>
        <template #name>
          Typeform Embed ID
        </template>
        <template #comment>
          Shows a type form survey on the users profile.
        </template>
        <v-text-field
          color="primary"
          label="ID"
          v-model="integrations.typeFormId"
        />
      </configuration-section-row>
      <configuration-section-row>
        <template #name>
          Crisp Chat ID
        </template>
        <template #comment>
          Support chat using Crisp.
        </template>
        <v-text-field
          color="primary"
          label="Crisp ID"
          v-model="integrations.crispChatId"
        />
      </configuration-section-row>

      <h1 class="text-center pt-3">{{ $t("components.IntegrationSelector.data_dash") }}</h1>
      <v-divider class="my-3" />

      <configuration-section-row>
        <template #name>
          Google Analytics
        </template>
        <v-text-field
          color="primary"
          label="GA Code"
          v-model="integrations.dataDashAppGoogleAnalyticsCode"
        />
      </configuration-section-row>

      <h1 class="text-center pt-3">{{ $t("components.IntegrationSelector.prep_app") }}</h1>
      <v-divider class="my-3" />
      <configuration-section-row>
        <template #name>
          Google Analytics
        </template>
        <v-text-field
          color="primary"
          label="GA Code"
          v-model="integrations.prepAppGoogleAnalyticsCode"
        />
      </configuration-section-row>

      <h1 class="text-center pt-3">{{ $t("components.IntegrationSelector.client_app") }}</h1>
      <v-divider class="my-3" />

      <configuration-section-row>
        <template #name>
          Google Analytics
        </template>
        <v-text-field
          color="primary"
          label="GA Code"
          v-model="integrations.googleAnalyticsCode"
        />
      </configuration-section-row>
      <configuration-section-row>
        <template #name>
          Google Tag Manager
        </template>
        <v-text-field
          color="primary"
          label="GTM Code"
          v-model="integrations.googleTagManagerCode"
        />
      </configuration-section-row>
      <configuration-section-row>
        <template #name>
          MixPanel ID
        </template>
        <v-text-field
          color="primary"
          label="ID"
          v-model="integrations.mixpanelId"
        />
      </configuration-section-row>
      <configuration-section-row>
        <template #name>
          Adobe Analytics Launch Script
        </template>
        <v-text-field
          color="primary"
          label="Script URL"
          v-model="integrations.adobeAnalyticsLaunchScript"
        />
      </configuration-section-row>
      <configuration-section-row>
        <template #name>
          Sentry DSN URL
        </template>
        <v-text-field
          color="primary"
          label="DSN URL"
          v-model="integrations.sentryDSNUrl"
        />
      </configuration-section-row>
      <configuration-section-row>
        <template #name>
          Sentry Tracking Environment
        </template>
        <v-select
          color="primary"
          label="Environment"
          v-model="integrations.sentryTrackingEnvironment"
          :items="['production', 'staging', 'development']"
        />
      </configuration-section-row>

    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn
        @click="reset"
        color="secondary"
        :disabled="!changed"
      >
        {{ $t("buttons.reset") }}
      </v-btn>
      <v-btn
        @click="confirmVisible = true"
        color="primary"
        :disabled="!changed"
      >
        {{ $t("buttons.apply") }}
      </v-btn>
    </v-card-actions>
  </v-card>

  <confirm-dialog
    :title="$t('components.IntegrationSelector.confirm_title')"
    :text="$t('components.IntegrationSelector.confirm_text')"
    v-model="confirmVisible"
    @confirm="save"
    @cancel="confirmVisible = false"
  />
</template>

<script setup lang="ts">
import {computed, ref} from "vue";
import {useGlobalStore} from "@/store";
import {useI18n} from "vue-i18n";
import {isEqual} from "lodash";
import {isYapiError, YapiError} from "@YenzaCT/sdk";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import ConfigurationSectionRow from "@/pages/AppConfigurationPage/components/ConfigurationSectionRow.vue";
import LabeledSwitch from "@/components/LabeledSwitch.vue";

const {t} = useI18n();
const store = useGlobalStore();

const integrations = ref({...store.appConfiguration.integrations});
const changed = computed(
  () => !isEqual(store.appConfiguration.integrations, integrations.value)
);
const confirmVisible = ref(false);

async function save() {
  try {
    await store.saveAppConfiguration("integrations", integrations.value);
    store.showSnackbar(t("components.IntegrationSelector.update_message"));
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    confirmVisible.value = false;
    reset();
  }
}

function reset() {
  integrations.value = {...store.appConfiguration.integrations};
}
</script>
